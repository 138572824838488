import React, { useState, useEffect } from 'react';
import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AccountPage from './components/AccountPage';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import Footer from './components/Footer';
import Header from './components/Header';
import ContactPage from './components/ContactPage';

const App = () => {
  // track whether the user is logged in
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Use useEffect to load the login state from localStorage when the component mounts
  useEffect(() => {
    const savedLoginState = localStorage.getItem('isLoggedIn');
    if (savedLoginState === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  // Update localStorage when the login state changes
  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
  }, [isLoggedIn]);

  // code for handling window resize
  const [isDesktop, setIsMobile] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth > 1070);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <Header isDesktop={isDesktop} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Routes>
        <Route path='/' element={<HomePage isDesktop={isDesktop} />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/account' element={<AccountPage isDesktop={isDesktop} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />} />
        <Route path='/contact' element={<ContactPage />} />
      </Routes>
      <Footer isDesktop={isDesktop} />
    </Router>
  );
};

export default App;
