import React from 'react';

const ContactPage = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', paddingRight: '10vw', paddingLeft: '10vw' }}>
          <h1 style={{ color: 'white' }}>Send us an email at info@splitrideshare.com</h1>
        </div>
      );
};

export default ContactPage;