import React, { useEffect } from 'react';
import Auth from './Auth';
import colleges from '../assets/colleges';
import { firestore } from '../firebase';
import { fromZonedTime } from 'date-fns-tz';

import { GoogleOAuthProvider } from '@react-oauth/google';

import { addDoc, collection, serverTimestamp, Timestamp } from '@firebase/firestore';

//const clientId = '799604415612-ur22p9u8tiq9r9n0p63juip2qd7g7bh1.apps.googleusercontent.com';
const clientId = '950716568830-qdufri34l2mi9dg1kbj4r443abaniv63.apps.googleusercontent.com';

const Form = ({ isDesktop, isLoggedIn, setIsLoggedIn, selectedCollege }) => {
  const needsLogin = selectedCollege && colleges[selectedCollege]['needs-login'];
  const ref = collection(firestore, 'Responses');

  const [hover, setHover] = React.useState(false);
  const [name, setName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [selectedRoute, setselectedRoute] = React.useState('');
  const [pickupDate, setPickupDate] = React.useState('');
  const [pickupTime, setPickupTime] = React.useState('');
  const [successOverlay, setSuccessOverlay] = React.useState(false);

  // Clear all fields when the selected college changes
  useEffect(() => {
    clearFields();
    setIsLoggedIn(false);
  }, [selectedCollege, setIsLoggedIn]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    const input = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    const formattedPhoneNumber = formatPhoneNumber(input);
    setPhoneNumber(formattedPhoneNumber);
  };

  const formatPhoneNumber = (input) => {
    const phoneNumberLength = input.length;
    if (phoneNumberLength < 4) return input;
    if (phoneNumberLength < 7) {
      return `(${input.slice(0, 3)}) ${input.slice(3)}`;
    }
    return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
  };

  const handleRouteChange = (event) => {
    setselectedRoute(event.target.value);
  };

  const handleDateChange = (event) => {
    setPickupDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setPickupTime(event.target.value);
  };

  const handleSubmit = () => {
    if (!name) {
      alert('Please enter your name.');
      return;
    }
    if (!phoneNumber || phoneNumber.length !== 14) {
      alert('Please enter a valid phone number.');
      return;
    }
    if (!selectedRoute) {
      alert('Please select an airport.');
      return;
    }
    if (!pickupDate) {
      alert('Please select a pickup date.');
      return;
    }
    if (!pickupTime) {
      alert('Please select a pickup time.');
      return;
    }

    // Combine pickupDate and pickupTime in the specified timezone
    const timezone = colleges[selectedCollege]['timezone'];
    const dateTimeString = `${pickupDate}T${pickupTime}:00`; // Including seconds

    // Convert local time in the selected timezone to UTC
    const dateInUtc = fromZonedTime(dateTimeString, timezone);

    // Convert to Firebase Timestamp
    const pickupTimestamp = Timestamp.fromDate(dateInUtc);

    const docData = {
      college: selectedCollege,
      confirmation: false,
      group: [],
      name: name,
      number: phoneNumber.replace(/\D/g, ''),
      pickup_time: pickupTimestamp,
      route: selectedRoute,
      timestamp: serverTimestamp(),
    };
    try {
      addDoc(ref, docData);
      setSuccessOverlay(true);
      clearSomeFields();
    } catch (e) {
      console.log('Error:');
      console.log(e);
    }
  };

  const clearSomeFields = () => {
    setselectedRoute('');
    setPickupDate('');
    setPickupTime('');
  };

  const clearFields = () => {
    setName('');
    setPhoneNumber('');
    setselectedRoute('');
    setPickupDate('');
    setPickupTime('');
  };

  const closeOverlay = () => setSuccessOverlay(false);

  const buttonStyle = {
    borderRadius: '100rem',
    padding: '1rem',
    fontFamily: 'DM Sans',
    letterSpacing: '0.47px',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#ffffff',
    border: 'solid 3px transparent',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #7ACEFF, #5B80FA, #956BFA)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    boxShadow: '2px 1000px 1px #02041D inset',
    transition: 'box-shadow 0.3s ease, color 0.3s ease',
    backgroundColor: 'rgb(26, 27, 43)',
    width: 200,
    marginTop: 30,
  };

  const buttonHoverStyle = {
    boxShadow: 'none',
    color: 'white',
    transition: 'box-shadow 0.3s ease, color 0.3s ease',
  };

  const sharedStyles = {
    container: {
      display: 'flex',
      flexDirection: isDesktop ? 'row' : 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: isDesktop ? 29 : 40,
      paddingLeft: isDesktop ? 140 : 5,
      paddingRight: isDesktop ? 15 : 5,
      paddingBottom: 29,
      width: '100vw',
      margin: '0 auto',
    },
    textStyle: {
      color: 'white',
      fontSize: isDesktop ? '40px' : '30px',
      fontFamily: 'DM Sans',
      fontWeight: '700',
      wordWrap: 'break-word',
    },
    gradientTextStyle: {
      background: 'linear-gradient(90deg, #7079FF, #E05F89, #FFB0B0)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontSize: isDesktop ? '40px' : '30px',
      fontFamily: 'DM Sans',
      fontWeight: '700',
      wordWrap: 'break-word',
      marginLeft: '10px',
    },
    subTextStyle: {
      color: 'white',
      fontSize: isDesktop ? '20px' : '15px',
      fontFamily: 'DM Sans',
      fontWeight: '400',
      wordWrap: 'break-word',
    },
    formItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: isDesktop ? '100px' : 0,
      paddingRight: isDesktop ? '300px' : 0,
    },
    formFieldContainer: {
      width: 500,
      maxWidth: isDesktop ? 496 : '90vw',
      height: 50,
      position: 'relative',
      marginBottom: 20,
    },
    formFieldBox: {
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      position: 'absolute',
      borderRadius: 50,
      border: '1px #D9DBE0 solid',
    },
    formFieldText: {
      left: 20,
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      color: '#757684',
      fontSize: 16,
      fontWeight: '400',
      wordWrap: 'break-word',
      fontFamily: 'DM Sans',
    },
    labelText: {
      color: '#D9DBE0',
      fontSize: 14,
      fontFamily: 'DM Sans',
      fontWeight: '400',
      wordWrap: 'break-word',
    },
    asterisk: {
      color: '#FFB0B0',
      fontSize: 14,
      fontFamily: 'DM Sans',
      fontWeight: '400',
      wordWrap: 'break-word',
      paddingLeft: 20,
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={sharedStyles.container}>
        {/* Left-aligned items */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '20px',
            flex: '1 0 50%',
            paddingBottom: isDesktop ? 100 : 30,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <span style={sharedStyles.textStyle}>Request a</span>
            <div style={sharedStyles.gradientTextStyle}>New Match</div>
          </div>
          <span style={sharedStyles.subTextStyle}>Need a ride? Fill out the form to connect with fellow students heading your way!</span>
        </div>

        {/* Right-aligned items */}
        <div style={sharedStyles.formItemContainer}>
          <div>
            <span style={sharedStyles.asterisk}>*</span>
            <span style={sharedStyles.labelText}>Name</span>
          </div>
          <div style={sharedStyles.formFieldContainer}>
            <div style={sharedStyles.formFieldBox} />
            <input
              type='text'
              placeholder='Name'
              value={name}
              onChange={handleNameChange}
              maxLength={35}
              style={{
                ...sharedStyles.formFieldText,
                width: 'calc(100% - 30px)',
                height: '100%',
                border: 'none',
                outline: 'none',
                background: 'none',
                padding: '0 10px',
              }}
            />
          </div>

          <div>
            <span style={sharedStyles.asterisk}>*</span>
            <span style={sharedStyles.labelText}>Phone Number</span>
          </div>
          <div style={sharedStyles.formFieldContainer}>
            <div style={sharedStyles.formFieldBox} />
            <input
              type='text'
              placeholder='(XXX) XXX-XXXX'
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength={14}
              style={{
                ...sharedStyles.formFieldText,
                width: 'calc(100% - 30px)',
                height: '100%',
                border: 'none',
                outline: 'none',
                background: 'none',
                padding: '0 10px',
              }}
            />
          </div>

          <div>
            <span style={sharedStyles.asterisk}>*</span>
            <span style={sharedStyles.labelText}>Route</span>
          </div>
          <div style={sharedStyles.formFieldContainer}>
            <div style={sharedStyles.formFieldBox} />
            <select
              value={selectedRoute}
              onChange={handleRouteChange}
              style={{
                ...sharedStyles.formFieldText,
                width: 'calc(100% - 30px)',
                height: '100%',
                border: 'none',
                outline: 'none',
                background: 'none',
                appearance: 'none',
                padding: '0 10px',
                cursor: 'pointer',
              }}>
              <option value='' disabled>
                Select your airport...
              </option>
              {selectedCollege &&
                colleges[selectedCollege]['route-options']?.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <span style={sharedStyles.asterisk}>*</span>
            <span style={sharedStyles.labelText}>Pickup Date</span>
          </div>
          <div style={sharedStyles.formFieldContainer}>
            <div style={sharedStyles.formFieldBox} />
            <input
              type='date'
              value={pickupDate}
              onChange={handleDateChange}
              style={{
                ...sharedStyles.formFieldText,
                width: 'calc(100% - 30px)',
                height: '45px',
                border: 'none',
                outline: 'none',
                background: 'none',
                padding: '0 10px',
                color: '#757684',
                cursor: 'pointer',
              }}
            />
          </div>
          <div>
            <span style={sharedStyles.asterisk}>*</span>
            <span style={sharedStyles.labelText}>Ideal Pickup Time{selectedCollege ? ` (${colleges[selectedCollege]?.['timezone-short']})` : ''}</span>
          </div>
          <div style={{ ...sharedStyles.formFieldContainer, marginBottom: 0 }}>
            <div style={sharedStyles.formFieldBox} />
            <input
              type='time'
              value={pickupTime}
              onChange={handleTimeChange}
              style={{
                ...sharedStyles.formFieldText,
                width: 'calc(100% - 30px)',
                height: '45px',
                border: 'none',
                outline: 'none',
                background: 'none',
                padding: '0 10px',
                color: '#757684',
                cursor: 'pointer',
              }}
            />
          </div>
          <span
            style={{
              color: '#D9DBE0',
              fontSize: 10,
              fontFamily: 'DM Sans',
              fontWeight: '400',
              wordWrap: 'break-word',
              fontStyle: 'italic',
            }}>
            You will be matched with someone within 45 minutes of this time
          </span>
          <button style={hover ? { ...buttonStyle, ...buttonHoverStyle } : buttonStyle} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>

      {!selectedCollege && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            fontFamily: 'DM Sans',
            zIndex: 20,
          }}>
          Please select your college
        </div>
      )}

      {successOverlay && (
        <div
          onClick={closeOverlay}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            fontFamily: 'DM Sans',
            zIndex: 20,
            cursor: 'pointer',
          }}>
          Your response has been submitted successfully! Click anywhere to fill out another entry.
        </div>
      )}

      {!isLoggedIn && needsLogin && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
          }}>
          <GoogleOAuthProvider clientId={clientId}>
            <Auth setIsLoggedIn={setIsLoggedIn} setName={setName} domain={colleges[selectedCollege]['domain']} />
          </GoogleOAuthProvider>
        </div>
      )}
    </div>
  );
};

export default Form;
