import React, { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const Auth = ({ setIsLoggedIn, setName, domain }) => {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const [buttonText, setButtonText] = useState("Log in with school-issued Google");

  const updateButtonText = (newText) => {
    setButtonText(newText);
  };

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: 'application/json',
          },
        })
        .then((res) => {
          if (res.data.email.endsWith(domain)) {
            setIsLoggedIn(true);
            setProfile(res.data);
            setName(res.data.name);
          } else {
            updateButtonText("You MUST verify with school-issued email");
            setUser([]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user, domain, setIsLoggedIn]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      if (codeResponse) {
        setUser(codeResponse);
      } else {
        updateButtonText("Google not responding");
      }
    },
    onError: (error) => console.log('Login Failed:', error),
  });

  return (
    <div
      className='auth-container'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <button
        onClick={login}
        style={{
          padding: '10px 20px',
          backgroundColor: '#4285F4',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          fontSize: '16px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#357ae8')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#4285F4')}
        onMouseDown={(e) => (e.target.style.backgroundColor = '#2f65c6')}
        onMouseUp={(e) => (e.target.style.backgroundColor = '#357ae8')}>
        {buttonText}
      </button>
    </div>
  );
};

export default Auth;
