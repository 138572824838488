import React from "react";

const HowSafeSplitWorks = ({ isDesktop }) => {
  const containerStyles = {
    width: '100vw',
    paddingTop: 50,
    paddingBottom: 50,
    background: '#101229',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    display: 'inline-flex',
    height: 'auto',
    paddingRight: isDesktop ? 50 : 15,
    paddingLeft: isDesktop ? 50 : 15
  };

  const textContainerStyles = {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    display: 'flex',
    height: 'auto'
  };

  const headerStyles = {
    color: 'white',
    fontSize: isDesktop ? '50px' : '30px',
    fontFamily: 'DM Sans',
    fontWeight: '700',
    wordWrap: 'break-word'
  };

  const gradientTextStyles = {
    background: 'linear-gradient(90deg, #7079FF, #E05F89, #FFB0B0)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: isDesktop ? '50px' : '30px',
    fontFamily: 'DM Sans',
    fontWeight: '700',
    wordWrap: 'break-word'
  };

  const stepTitleStyles = {
    color: '#A9DFFF',
    fontSize: isDesktop ? 20 : 15,
    fontFamily: 'DM Sans',
    fontWeight: '700',
    wordWrap: 'break-word'
  };

  const stepTextStyles = {
    color: 'white',
    fontSize: isDesktop ? 20 : 15,
    fontFamily: 'DM Sans',
    fontWeight: '400',
    wordWrap: 'break-word'
  };

  return (
    <div style={containerStyles}>
      <div style={textContainerStyles}>
        <div style={textContainerStyles}>
          <div style={{ textAlign: 'center', height: 'auto' }}>
            <span style={headerStyles}>How SafeSplit</span>
            <span style={gradientTextStyles}> Works</span>
          </div>
          <div style={{ textAlign: 'center' }}>
            <span style={stepTitleStyles}>Step 1:</span>
            <span style={stepTextStyles}> Fill out the form below with your ride details.</span>
          </div>
          <div style={{ textAlign: 'center' }}>
            <span style={stepTitleStyles}>Step 2:</span>
            <span style={stepTextStyles}> We’ll conect you and your match via text. You coordinate transportation from there!</span>
          </div>
        </div>
      </div>
    </div>
  );
};


export default HowSafeSplitWorks;