import React, { useState } from 'react';
import logo from '../assets/Logo.png';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Header = ({ isDesktop }) => {

  const buttonStyle = {
    borderRadius: '100rem',
    padding: '1rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    fontFamily: 'DM Sans',
    letterSpacing: '0.47px',
    fontWeight: 'bold',
    fontSize: '1rem',
    color: '#ffffff',
    border: 'solid 3px transparent',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #7ACEFF, #5B80FA, #956BFA)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    boxShadow: '2px 1000px 1px #1A1B2B inset',
    transition: 'box-shadow 0.3s ease, color 0.3s ease',
    backgroundColor: 'rgb(26, 27, 43)',
  };

  const buttonHoverStyle = {
    boxShadow: 'none',
    color: 'white',
    transition: 'box-shadow 0.3s ease, color 0.3s ease',
  };

  const [hover, setHover] = React.useState(false);
  const [hoverHome, setHoverHome] = React.useState(false);
  const [hoverAbout, setHoverAbout] = React.useState(false);
  const [hoverContact, setHoverContact] = React.useState(false);

  const linkButtonStyle = {
    fontSize: '16px',
    fontFamily: 'DM Sans',
    fontWeight: '500',
    paddingLeft: '20px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };





// Mobile menu
if (!isDesktop) {
  return (
    
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '40px',
        marginBottom: '40px',
        flexGrow: 1,
      }}
    >
      {/* Mobile Header with Menu Icon */}
      <div
        style={{
          width: '90vw',
          height: 70,
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 29,
          paddingBottom: 29,
          background: '#1A1B2B',
          borderRadius: 102,
          overflow: 'hidden',
          backdropFilter: 'blur(50px)',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          margin: '0 auto',
        }}
      >
        {/* Left-aligned Logo and Title */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Link to='/'>
            <img src={logo} alt='Logo' style={{ height: 20 }} />
          </Link>
          <Link to='/'>
            <div
              style={{
                color: '#FFFFFF',
                fontSize: 25,
                fontFamily: 'DM Sans',
                fontWeight: '500',
              }}
            >
              SPLIT
            </div>
          </Link>
        </div>

        {/* Right-aligned Menu Icon */}
        <div onClick={handleOpen} style={{ cursor: 'pointer' }}>
          {open ? <AiOutlineClose size={20} color='white' /> : <AiOutlineMenu size={20} color='white' />}
        </div>
      </div>

      {/* Gray Overlay */}
      {open && (
        <div
          onClick={handleOpen}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent gray
            zIndex: 9,
            pointerEvents: 'auto'
          }}
        />
      )}

      {/* Sidebar Overlay with Slide Animation */}
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: open ? 0 : '-60%', // Slide animation: -60% when closed
          width: '60%',
          height: '100%',
          backgroundColor: '#1A1B2B',
          zIndex: 10,
          display: 'flex',
          flexDirection: 'column',
          paddingTop: '20px',
          paddingLeft: '20px',
          transition: 'left 0.3s ease-in-out', // Sliding animation
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <h1 style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>SPLIT</h1>
          <div onClick={handleOpen} style={{ marginLeft: 'auto', paddingRight: '20px', cursor: 'pointer' }}>
            <AiOutlineClose size={20} color='white' />
          </div>
        </div>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ marginBottom: '20px' }}>
            <Link to="/" onClick={handleOpen} style={{ color: 'white', fontSize: '18px', textDecoration: 'none' }}>
              Home
            </Link>
          </li>
          <li style={{ marginBottom: '20px' }}>
            <Link to="/about" onClick={handleOpen} style={{ color: 'white', fontSize: '18px', textDecoration: 'none' }}>
              About
            </Link>
          </li>
          <li style={{ marginBottom: '20px' }}>
            <Link to="/contact" onClick={handleOpen} style={{ color: 'white', fontSize: '18px', textDecoration: 'none' }}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

  // Desktop menu
  return (

    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '40px',
        marginBottom: '40px',
        flexGrow: 1,
      }}>
      <div
        style={{
          width: '90vw',
          height: 108,
          paddingLeft: 60,
          paddingRight: 60,
          paddingTop: 29,
          paddingBottom: 29,
          background: 'rgba(26, 27, 43, 1)',
          borderRadius: 102,
          overflow: 'hidden',
          backdropFilter: 'blur(50px)',
          flexDirection: 'row', // Changed to row for horizontal alignment
          justifyContent: 'space-between', // Justifies content space-between
          alignItems: 'center', // Align items centrally
          display: 'flex',
          margin: '0 auto',
        }}>
        {/* Left-aligned items */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <Link to='/'>
            <img src={logo} alt='Logo' />
          </Link>

          <Link to='/'>
            <div
              style={{
                color: '#FFFFFF',
                fontSize: 40,
                fontFamily: 'DM Sans',
                fontWeight: '500',
                paddingRight: 50,
              }}>
              SPLIT
            </div>
          </Link>

          <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', gap: '10px' }}>
            <button
              style={{ ...linkButtonStyle, color: hoverHome ? '#FFFFFF' : '#757684' }}
              onMouseEnter={() => setHoverHome(true)}
              onMouseLeave={() => setHoverHome(false)}
              onClick={() => (window.location.href = '/')}>
              Home
            </button>
            <button
              style={{ ...linkButtonStyle, color: hoverAbout ? '#FFFFFF' : '#757684' }}
              onMouseEnter={() => setHoverAbout(true)}
              onMouseLeave={() => setHoverAbout(false)}
              onClick={() => (window.location.href = '/about')}>
              About
            </button>
            <button
              style={{ ...linkButtonStyle, color: hoverContact ? '#FFFFFF' : '#757684' }}
              onMouseEnter={() => setHoverContact(true)}
              onMouseLeave={() => setHoverContact(false)}
              onClick={() => (window.location.href = '/contact')}>
              Contact Us
            </button>
          </div>
        </div>

        {/* Right-aligned items */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

          <button
            style={hover ? { ...buttonStyle, ...buttonHoverStyle } : buttonStyle}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => (window.location.href = '/account')}>
            {' '}
            Request a Ride{' '}
          </button>
        </div>
      </div>
    </div>

  );
};

export default Header;
