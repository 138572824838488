import React from 'react';
import colleges from '../assets/colleges';

const SelectSchool = ({ isDesktop, selectedCollege, setSelectedCollege }) => {

  const handleCollegeChange = (event) => {
    const newCollege = event.target.value || null;
    setSelectedCollege(newCollege);
  };

  const sharedStyles = {
    container: {
      display: 'flex',
      flexDirection: isDesktop ? 'row' : 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: isDesktop ? 29 : 40,
      paddingLeft: isDesktop ? 140 : 5,
      paddingRight: isDesktop ? 15 : 5,
      paddingBottom: 29,
      width: '100vw',
      margin: '0 auto',
    },
    textStyle: {
      color: 'white',
      fontSize: isDesktop ? '40px' : '30px',
      fontFamily: 'DM Sans',
      fontWeight: '700',
      wordWrap: 'break-word',
    },
    gradientTextStyle: {
      background: 'linear-gradient(90deg, #7079FF, #E05F89, #FFB0B0)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontSize: isDesktop ? '40px' : '30px',
      fontFamily: 'DM Sans',
      fontWeight: '700',
      wordWrap: 'break-word',
      marginLeft: '10px',
    },
    subTextStyle: {
      color: 'white',
      fontSize: isDesktop ? '20px' : '15px',
      fontFamily: 'DM Sans',
      fontWeight: '400',
      wordWrap: 'break-word',
    },
    formItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: isDesktop ? '100px' : 0,
      paddingRight: isDesktop ? '300px' : 0,
    },
    formFieldContainer: {
      width: 500,
      maxWidth: isDesktop ? 496 : '90vw',
      height: 50,
      position: 'relative',
      marginBottom: 20,
    },
    formFieldBox: {
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      position: 'absolute',
      borderRadius: 50,
      border: '1px #D9DBE0 solid',
    },
    formFieldText: {
      left: 20,
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      color: '#757684',
      fontSize: 16,
      fontWeight: '400',
      wordWrap: 'break-word',
      fontFamily: 'DM Sans',
    },
    labelText: {
      color: '#D9DBE0',
      fontSize: 14,
      fontFamily: 'DM Sans',
      fontWeight: '400',
      wordWrap: 'break-word',
    },
    asterisk: {
      color: '#FFB0B0',
      fontSize: 14,
      fontFamily: 'DM Sans',
      fontWeight: '400',
      wordWrap: 'break-word',
      paddingLeft: 20,
    },
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={sharedStyles.container}>
        {/* Left-aligned items */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '20px',
            flex: '1 0 50%',
            paddingBottom: isDesktop ? 20 : 30,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <span style={sharedStyles.textStyle}>Select your</span>
            <div style={sharedStyles.gradientTextStyle}>College</div>
          </div>
        </div>

        {/* Right-aligned items */}
        <div style={sharedStyles.formItemContainer}>
          <div>
            <span style={sharedStyles.asterisk}>*</span>
            <span style={sharedStyles.labelText}>College</span>
          </div>
          <div style={sharedStyles.formFieldContainer}>
            <div style={sharedStyles.formFieldBox} />
            <select
              value={selectedCollege || ""}
              onChange={handleCollegeChange}
              style={{
                ...sharedStyles.formFieldText,
                width: 'calc(100% - 30px)',
                height: '100%',
                border: 'none',
                outline: 'none',
                background: 'none',
                appearance: 'none',
                padding: '0 10px',
                cursor: 'pointer',
              }}>
              <option value='' disabled>
                Select your college...
              </option>
              {Object.keys(colleges).map((collegeKey) => (
                <option key={collegeKey} value={collegeKey}>
                  {collegeKey}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectSchool;
