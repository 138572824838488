import React from 'react';

const AboutPage = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', paddingRight: '10vw', paddingLeft: '10vw' }}>
      <h1 style={{ color: 'white' }}>Welcome to SafeSplit! Our mission to promote safe and efficient airport transportation among students. We do this by matching students traveling to and from the airport at similar times. Fill out our form to be matched!</h1>
    </div>
  );
};

export default AboutPage;
