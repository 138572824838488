import car from '../assets/Car.png';
import React from "react";


const HomePage = ({ isDesktop }) => {
    const commonButtonStyles = {
      borderRadius: '100rem',
      padding: '1rem',
      fontFamily: 'DM Sans',
      letterSpacing: '0.47px',
      fontWeight: 'bold',
      fontSize: '1rem',
      color: '#ffffff',
      border: 'solid 3px transparent',
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, #7ACEFF, #5B80FA, #956BFA)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      boxShadow: '2px 1000px 1px #02041D inset',
      transition: 'box-shadow 0.3s ease, color 0.3s ease',
      backgroundColor: 'rgb(26, 27, 43)',
    };
  
    const buttonStyle = {
      ...commonButtonStyles,
      width: isDesktop ? '15vw' : '40vw',
    };
  
    const buttonHoverStyle = {
      boxShadow: 'none',
      color: 'white',
    };
  
    const [hover, setHover] = React.useState(false);
  
    const commonTextStyle = {
      color: '#D9DBE0',
      fontFamily: 'DM Sans',
      fontWeight: '700',
      lineHeight: 1,
      wordWrap: 'break-word',
    };
  
    const titleStyle = {
      ...commonTextStyle,
      fontSize: isDesktop ? '4vw' : '8vw',
    };
  
    const gradientTextStyle = {
      background: 'linear-gradient(90deg, #7079FF, #E05F89, #FFB0B0)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontSize: isDesktop ? '4vw' : '8vw',
      fontFamily: 'DM Sans',
      fontWeight: '700',
      wordWrap: 'break-word',
    };
  
    const descriptionStyle = {
      width: isDesktop ? '40vw' : '100vw',
      color: 'white',
      fontSize: isDesktop ? 20 : 13,
      fontFamily: 'DM Sans',
      fontWeight: '400',
      lineHeight: 1.5,
      wordWrap: 'break-word',
      paddingTop: 15,
      paddingBottom: isDesktop ? 30 : 25,
      paddingRight: isDesktop ? 0 : 20,
      textAlign: 'left',
    };
  
    const containerStyle = {
      display: 'flex',
      flexDirection: isDesktop ? 'row' : 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: isDesktop ? '10vw' : 0,
    };
  
    const imageStyle = {
      height: isDesktop ? 400 : 'auto',
      maxHeight: isDesktop ? 'none' : 250,
      paddingRight: isDesktop ? 50 : '10vw',
      paddingLeft: isDesktop ? 50 : '10vw',
      paddingBottom: isDesktop ? 0 : 20,
    };
  
    return (
      <div style={containerStyle}>
        {!isDesktop && (
          <img src={car} alt="Logo" style={imageStyle} />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingLeft: isDesktop ? 0: 20}}>
          <div>
            <span style={titleStyle}>Save on your Airport<br /></span>
            <span style={gradientTextStyle}> transportation </span>
            <span style={titleStyle}>costs</span>
          </div>
          <div style={descriptionStyle}>
            Split transportation costs & ride safer by matching with fellow students who are going to or from the airport to campus at the same time as you! 100% free!
          </div>
          <button
            style={hover ? { ...buttonStyle, ...buttonHoverStyle } : buttonStyle}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => window.location.href = '/account'}
          >
            Get Started
          </button>
        </div>
        {isDesktop && (
          <img src={car} alt="Logo" style={imageStyle} />
        )}
      </div>
    );
  };
  
  
export default HomePage;