import React from 'react';
import '../styles/Footer.css';
import logo from '../assets/Logo.png';

const Footer = ({ isDesktop }) => {
  const commonStyles = {
    fontFamily: 'DM Sans, sans-serif',
    fontWeight: '400',
    wordWrap: 'break-word',
  };

  return (
    <div>
      <div
        style={{
          width: isDesktop ? '80%' : '90%',
          height: 0,
          border: '1px #757684 solid',
          margin: 'auto',
          marginTop: '40px',
        }}></div>
      <div className='container' style={{ flexDirection: isDesktop ? 'row' : 'column' }}>
        <div className='section'>
          <div style={{ display: 'flex', paddingTop: isDesktop ? 50 : 20, alignItems: 'center' }}>
            <img src={logo} alt='Logo' style={{ height: 40, paddingRight: 10 }} />
            <div style={{ ...commonStyles, color: '#D9DBE0', fontSize: 40 }}>SPLIT</div>
          </div>
          <div style={{ ...commonStyles, color: '#D9DBE0', fontSize: 16, lineHeight: 1.3, paddingTop: '5px' }}>
            Connecting Students,
            <br />
            Splitting Costs, Ensuring Safety
          </div>
        </div>
        <div className='section'></div>
        <div className='section'>
          <div style={{ ...commonStyles, color: '#757684', fontSize: isDesktop ? 14 : 20, paddingTop: isDesktop ? '40px' : '20px' }}>Website</div>
          {['Home', 'About', 'Contact Us'].map((text) => (
            <a key={text} href={text === 'Home' ? '/' : text === 'About' ? '/about' : '/contact'} style={{ textDecoration: 'none' }}>
              <div style={{ ...commonStyles, color: '#D9DBE0', fontSize: 16, paddingTop: '10px' }}>{text}</div>
            </a>
          ))}
        </div>
        <div className='section'>
          <div style={{ ...commonStyles, color: '#757684', fontSize: isDesktop ? 14 : 20, paddingTop: isDesktop ? '40px' : '20px' }}>Resources</div>
          {['Facebook', 'LinkedIn', 'Instagram'].map((text) => (
            <a key={text} href={text === 'Facebook' ? 'https://www.facebook.com/profile.php?id=100093943644868' : text === 'LinkedIn' ? 'https://www.linkedin.com/company/96098594/admin/dashboard/' : 'https://www.instagram.com/northwesternsplit/?hl=en'} style={{ textDecoration: 'none' }}>
              <div style={{ ...commonStyles, color: '#D9DBE0', fontSize: 16, paddingTop: '10px' }}>{text}</div>
            </a>
          ))}
        </div>
      </div>

      <div
        style={{
          width: isDesktop ? '80%' : '90%',
          height: 0,
          border: '1px #757684 solid',
          margin: 'auto',
          marginTop: '50px',
        }}></div>
      <div
        style={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '80%',
          margin: '0 auto',
          color: '#D9DBE0',
          paddingTop: '40px',
          paddingBottom: '40px',
        }}>
        {['', '', ''].map(
          (
            text, // edit to terms & conditions, privacy policy, and status
          ) => (
            <div key={text} style={{ ...commonStyles, fontSize: 16, paddingRight: '20px' }}>
              {text}
            </div>
          ),
        )}
        <div style={{ textAlign: 'right', ...commonStyles, fontSize: 16 }}>Copyright © 2024 SafeSplit LLC</div>
      </div>
    </div>
  );
};

export default Footer;
