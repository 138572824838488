import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HowSafeSplitWorks from './HowSafeSplitWorks';
import Form from './Form';
import SelectSchool from './SelectSchool';


const AccountPage = ({ isDesktop, isLoggedIn, setIsLoggedIn }) => {

    const [selectedCollege, setSelectedCollege] = useState(null);

    // Clear selectedCollege when page changes
    const location = useLocation();
    useEffect(() => {
        setSelectedCollege(null);
        setIsLoggedIn(false);
    }, [location.pathname, setIsLoggedIn]);

    return (
        <div>
            <HowSafeSplitWorks isDesktop={isDesktop}/>
            <SelectSchool isDesktop={isDesktop} selectedCollege={selectedCollege} setSelectedCollege={setSelectedCollege}/>
            <Form isDesktop={isDesktop} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} selectedCollege={selectedCollege}/>
        </div>
    )
};

export default AccountPage;