import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC8NjK_Swo42aOImlgxmZKqq9ssrjsIiyE",
    authDomain: "split-3-3cef5.firebaseapp.com",
    projectId: "split-3-3cef5",
    storageBucket: "split-3-3cef5.firebasestorage.app",
    messagingSenderId: "188015605928",
    appId: "1:188015605928:web:aa8de815ff81d2a983aa9d",
    measurementId: "G-3MKDN8CRPZ"
  };

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
// export const auth = getAuth(app);
// export default app;