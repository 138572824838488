const colleges = {
    "Northwestern University": {
        "domain": "northwestern.edu",
        "example-email": "bobsmith2027",
        "route-options": ["ORD to Northwestern", "MDW to Northwestern", "Northwestern to ORD", "Northwestern to MDW"],
        "timezone": "America/Chicago",
        "timezone-short": "CST",
        "needs-login": true,
    },
    "University of California, Berkeley": {
        "domain": "berkeley.edu",
        "example-email": "calbear2027",
        "route-options": ["SFO to UCB", "OAK to UCB", "UCB to SFO", "UCB to OAK"],
        "timezone": "America/Los_Angeles",
        "timezone-short": "PST",
        "needs-login": false,
    },
    "University of Chicago": {
        "domain": "uchicago.edu",
        "example-email": "phoenix2027",
        "route-options": ["ORD to UChicago", "MDW to UChicago", "UChicago to ORD", "UChicago to MDW"],
        "timezone": "America/Chicago",
        "timezone-short": "CST",
        "needs-login": false,
    },
    "University of Virginia": {
        "domain": "virginia.edu",
        "example-email": "ab1cd",
        "route-options": ["RIC to UVA", "CHO to UVA", "UVA to RIC", "UVA to CHO"],
        "timezone": "America/New_York",
        "timezone-short": "EST",
        "needs-login": false,
    },
    "Duke University": {
        "domain": "duke.edu",
        "example-email": "blueDevil2027",
        "route-options": ["RDU to Duke", "Duke to RDU"],
        "timezone": "America/New_York",
        "timezone-short": "EST",
        "needs-login": false,
    },
    "University of Southern California": {
        "domain": "usc.edu",
        "example-email": "trojan2027",
        "route-options": ["LAX to USC", "USC to LAX"],
        "timezone": "America/Los_Angeles",
        "timezone-short": "PST",
        "needs-login": false,
    },
    "Case Western Reserve University": {
        "domain": "case.edu",
        "example-email": "cwru2027",
        "route-options": ["CLE to CWRU", "CWRU to CLE"],
        "timezone": "America/New_York",
        "timezone-short": "EST",
        "needs-login": false,
    },
    "Notre Dame": {
        "domain": "nd.edu",
        "example-email": "irish2027",
        "route-options": ["ORD to ND", "MDW to ND", "SBN to ND", "ND to ORD", "ND to MDW", "ND to SBN"],
        "timezone": "America/New_York",
        "timezone-short": "EST",
        "needs-login": false,
    }
};

export default colleges;